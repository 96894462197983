import React from "react";

const timelineEvents = [
  {
    date: "July 2020",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop-code" />,
    title: "Launch QCS API v1",
    subtitle: "Rigetti Computing",
    text: (
      <div>
        <p>
          We launched the first production-quality, schema-driven web API for
          Rigetti QCS.{"  "}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </p>
        <p>
          Maintenance is automated: after merge, changes to any one of several
          microservices trigger updates, comprehensive tests, and automated
          promotions, reaching production in minutes. Launch also included a new
          Python SDK and a Golang CLI, both of which are generated and
          distributed automatically.
        </p>
        <p>
          This is an important, significant step ahead of the
          experimental-quality web service upon which QCS had originally been
          launched.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "December 2019",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop-code" />,
    title: "Led Integration with AWS",
    subtitle: "Rigetti Computing",
    text: (
      <div>
        <p style={{ textAlign: "center" }}>
          <a href="https://www.geekwire.com/2019/aws-launches-amazon-braket-service-part-broad-quantum-computing-initiative/">
            <span style={{ fontWeight: "bold" }}>
              Press: AWS Launches Amazon Braket Service
            </span>
            <img
              src={
                "https://cdn.geekwire.com/wp-content/uploads/2019/12/191202-rigetti2-630x355.jpg"
              }
              className="graphic"
            />
          </a>
        </p>
        <p>
          This was fascinating, truly full-stack project with a great team, and
          made for a fast start at Rigetti.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "September 2019 - Present",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop-code" />,
    title: <>Director, Software Engineering</>,
    subtitle: "Rigetti Computing",
    text: (
      <div>
        <img
          src={require("assets/images/rigetti-puck.jpg")}
          className="graphic"
        />
        <p>
          Full stack like you've never seen it: from UI and web services to
          embedded software, signal processing, and compilers, Rigetti does it
          all. I get to work with some of the smartest people I've ever met, and
          I learn something new every day.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "March 2018 - September 2019",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop-code" />,
    title: "Systems Development Engineer",
    subtitle: "Amazon Web Services (EC2)",
    text: (
      <div>
        <img src={require("assets/images/logo/aws.svg")} className="graphic" />
        <p>
          My team was the focal point for hundreds of highly specialized,
          globally-distributed engineers and teams working to maintain service
          parity between commercial regions and a special-purpose environment.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "November 2017 - January 2018",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop-code" />,
    title: "Security Analyst (Shift Fellow)",
    subtitle: "Affirm, Inc.",
    text: (
      <div>
        <a href="https://www.shift.org" className="graphic">
          <img
            src={require("assets/images/affirm-shift.png")}
            alt="Affirm &amp; Shift"
          ></img>
        </a>
        <p>
          Pilot participant in a brand-new fellowship program built to help
          servicemembers transition to high-growth companies. Integrated FOSS
          security tools to automate internal security assessments, and
          developed a web application to improve data ETL workflows.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "August - November 2017",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop-code" />,
    title: (
      <React.Fragment>
        Lead Engineer - Private Cloud &amp; Training Network
        <br />
        Instructor
      </React.Fragment>
    ),
    subtitle: "US Army Cyber School",
    text: (
      <div>
        <img
          src={require("assets/images/vta-thumbnail-600x422.png")}
          alt="Cyber School Training Network Diagram"
          className="graphic"
        />
        <p>
          Maintained and developed a private, 100% open-source OpenStack
          deployment, the core platform used by 300+ local and remote Cyber
          Warfare students for no-holds-barred OS, network, malware analysis,
          and penetration testing instruction. Components include FreeIPA,
          GitLab CE, and SaltStack for orchestration.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "2017 - Current",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-chalkboard-teacher" />,
    title: "MS, Computer Science (Ongoing)",
    subtitle: "Georgia Institute of Technology",
    text: (
      <div>
        <img src={require("assets/images/logo/georgia-tech-400x200.png")} />
        <p>Current GPA: 4.0</p>
        <p>Graduation Dec 2020</p>
      </div>
    ),
    tag: "education",
  },
  {
    date: "January - August 2017",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop" />,
    title: "Cyber Officer Operations Course",
    subtitle: "US Army Cyber School",
    text: (
      <div>
        <img
          src={require("assets/images/cyber-course-graduation.jpg")}
          className="graphic"
        />
        <p>
          Distinguished Honor Graduate
          <br />
          Rank: 1<sup>st</sup> of 17
        </p>
      </div>
    ),
    tag: "education",
  },
  {
    date: "June - December 2016",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-chalkboard-teacher" />,
    title: "Military Intelligence Captains Career Course",
    subtitle: "US Army Military Intelligence School",
    tag: "education",
  },
  {
    date: "June - December 2016",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-user-tie" />,
    title: "Mobile Training Team Lead",
    subtitle: "US Army - 2nd Cavalry Regiment - Ukraine",
    text: (
      <div>
        <img
          src={require("assets/images/q36-delivery.jpg")}
          alt="Delivery Ceremony"
          className="graphic"
        ></img>
        <p>
          Led a technical team to Ukraine to field $24 million advanced weapons
          systems directly to the combat operators and establish the national
          counter-artillery radar training program. Received visits by
          high-level dignitaries to include the Ukrainian President, US
          Congressional staff, and international media; finished ahead of
          schedule and under budget. Personally commended for success and
          decorated by the Ukrainian Minister of Defense.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "March 2014 - April 2016",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-user-tie" />,
    title: "Battalion Operations Officer",
    subtitle: "US Army, 2nd Cavalry Regiment · Germany",
    text: (
      <div>
        <img
          src={require("assets/images/toc-600x375.jpg")}
          alt="Range Tower"
          className="graphic"
        ></img>
        <p>
          Planned, coordinated, and executed operations and training of a
          650-man artillery battalion on a daily basis. Selected to manage the
          unit’s most ambitious projects, I built a 140-Soldier unit from the
          ground up with little guidance or precedent and designed a process to
          reduce combat command center deployment time from 4 hours to an hour
          and a half.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    date: "January 2013 - March 2014",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-user-tie" />,
    title: (
      <React.Fragment>
        Fire Support Team Lead
        <br />
        Intelligence Team Lead
        <br />
        Contracting Officer Representative
      </React.Fragment>
    ),
    subtitle: "US Army, 2nd Cavalry Regiment · Afghanistan & Germany",
    text: (
      <div>
        <img
          src={require("assets/images/afghan-meeting-600x375.jpg")}
          alt="Meeting with Locals"
          className="graphic"
        />
        <p>
          Coordinated all artillery, air support, intelligence, and engineering
          assets for a 140-man infantry troop. Responsible for a
          600-square-kilometer district aside a critical airbase in the
          heartland of the Taliban. Served as chief liaison between an infantry
          troop and our twelve military, government, and Afghan partners.
        </p>
      </div>
    ),
    tag: "professional",
  },
  {
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-user-tie" />,
    title: "Arab Spring",
    subtitle: "Cairo, Egypt",
    text: (
      <div>
        <p>
          <a href="http://www.centcom.mil/MEDIA/NEWS-ARTICLES/News-Article-View/Article/884242/west-point-cadets-witness-history-in-egypt/">
            <span style={{ fontWeight: "bold" }}>
              Press: Cadets Witness History in Egypt
            </span>
          </a>
        </p>
      </div>
    ),
    tag: "personal",
  },
  {
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-user-tie" />,
    title: "Parachute Demonstration Team",
    subtitle: "U.S. Military Academy at West Point",
    text: (
      <div>
        <img
          src={require("assets/images/skydive-six-way.jpg")}
          className="graphic"
        />
        <a href="https://youtu.be/pIZdITcEniY">
          <p>Link: A Recruitment Video I Made in 2010</p>
        </a>
      </div>
    ),
    tag: "personal",
  },
  {
    date: "2008 - 2012",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-chalkboard-teacher" />,
    title: "BS, Arabic Language & Mathematical Studies",
    subtitle: "United States Military Academy at West Point",
    text: (
      <div>
        <img
          src={require("assets/images/logo/west-point-400.png")}
          className="graphic"
        />
        <p>
          GPA: 4.05 · Rank: 8<sup>th</sup> of 1030
        </p>
      </div>
    ),
    tag: "education",
  },
  {
    date: "1995",
    iconStyle: { background: "rgb(48,48,48)", color: "#fff" },
    icon: <i className="fas fa-laptop-code" />,
    title: "First Programming Class",
    subtitle: "Elementary School",
    text: (
      <div>
        <img
          src={require("assets/images/computer-kid.png")}
          className="graphic"
        />
        <p>Took my first computer class, got hooked for life.</p>
      </div>
    ),
    tag: "personal",
  },
];

export default timelineEvents;
