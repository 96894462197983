import React from "react";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from "react-simple-maps";

import Header from "components/Header";

import { travelLog, adventureJournal } from "assets/data/travel";

import mapData from "assets/topo-json/world-110m.json";

const baseColor = "#000";
const visitedColor = "#20999e";
const hoverColor = "#aaa";
const selectedColor = "#eee";

const excludeCountries = ["Antarctica"];

const hasVisited = (geography) => {
  return travelLog.includes(geography.properties.NAME_LONG);
};

// example geography object
// geometry: {type: "MultiPolygon", coordinates: Array(127)}
// properties:
// ABBREV: "U.S.A."
// CONTINENT: "North America"
// FORMAL_EN: "United States of America"
// GDP_MD_EST: 18560000
// GDP_YEAR: 2016
// ISO_A2: "US"
// ISO_A3: "USA"
// NAME: "United States of America"
// NAME_LONG: "United States"
// POP_EST: 326625791
// POP_RANK: 17
// POP_YEAR: 2017
// REGION_UN: "Americas"
// SUBREGION: "Northern America"

console.log(mapData);

const MapLegend = () => (
  <div className="legend">
    <div className="legend-key">
      <span className="legend-color" style={{ background: visitedColor }} />
      <span className="legend-text">Visited</span>
    </div>
    <div className="legend-key">
      <span className="legend-color" style={{ background: baseColor }} />
      <span className="legend-text">Bucket List</span>
    </div>
  </div>
);

class TravelMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geography: null,
      selectedAdventure: adventureJournal[0],
    };
  }

  handleClick = (geography, event) => {
    this.setState({ geography });
  };

  handleClickMarker = (marker, coordinates, event) => {
    this.setState({ selectedAdventure: marker });
  };

  render() {
    // const { selectedAdventure } = this.state;
    return (
      <div>
        <Header title="Adventures" />
        <div className="travel-container">
          <div className="travel">
            <div className="world-map">
              <ComposableMap style={{ width: "100%" }}>
                <ZoomableGroup disablePanning={true}>
                  <Geographies geography={mapData}>
                    {({ geographies, projection }) =>
                      (geographies || [])
                        .filter(
                          (geography) =>
                            !excludeCountries.includes(
                              geography.properties.NAME_LONG
                            )
                        )
                        .map((geography, index) => (
                          <Geography
                            key={index}
                            geography={geography}
                            projection={projection}
                            onClick={this.handleClick}
                            style={{
                              default: {
                                fill: hasVisited(geography)
                                  ? visitedColor
                                  : baseColor,
                                // stroke: "#607D8B",
                                // strokeWidth: 0.75,
                                outline: "none",
                              },
                              hover: {
                                fill: hasVisited(geography)
                                  ? hoverColor
                                  : baseColor,
                                // stroke: "#607D8B",
                                // strokeWidth: 0.75,
                                outline: "none",
                              },
                              pressed: {
                                fill: hasVisited(geography)
                                  ? selectedColor
                                  : baseColor,
                                stroke: "#607D8B",
                                strokeWidth: 0.75,
                                outline: "none",
                              },
                            }}
                          />
                        ))
                    }
                  </Geographies>
                  {/* <Markers>
                  {adventureJournal.map((marker, i) => (
                    <Marker
                      key={i}
                      marker={marker}
                      onClick={this.handleClickMarker}
                      style={{
                        default: { stroke: "#ddd", outline: 'none' },
                        hover: { stroke: "#fff", outline: 'none' },
                        pressed: { stroke: "#fff", outline: 'none' },
                      }}
                    >
                      <g transform="translate(-12, -24)">
                        <path
                          fill="#fff"
                          strokeWidth="2"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeLinejoin="miter"
                          d="M20,9c0,4.9-8,13-8,13S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z"
                        />
                        <circle
                          fill="#000"
                          strokeWidth="2"
                          strokeLinecap="square"
                          strokeMiterlimit="10"
                          strokeLinejoin="miter"
                          cx="12"
                          cy="9"
                          r="3"
                        />
                      </g>
                    </Marker>
                  ))}
                </Markers> */}
                </ZoomableGroup>
              </ComposableMap>
              <MapLegend />
            </div>

            {/* <div className='detail'>
            <div className='header'>
              {selectedAdventure ? selectedAdventure.title : 'No Selection'}
            </div>
            <div className='content'>
              {selectedAdventure.text}
            </div>
          </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default TravelMap;
