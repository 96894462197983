import React from "react";

import Header from "components/Header";

import portfolio from "assets/data/portfolio";

// Each version is a new batch of icons generated at fontello.com
import "assets/logo-fonts/fontello-v1/css/fontello.css";

const Portfolio = () => (
  <div>
    {/* <h1>Portfolio</h1>
    <ReturnHomeButton /> */}
    <Header title="Portfolio" />

    <div className="cards">
      {portfolio.map((item, index) => (
        <div className="card" key={index}>
          <h3 className="header">{item.title}</h3>
          <div className="content">
            <p>{item.text}</p>
            {item.sections.map((section, index) =>
              section.custom ? (
                section.custom
              ) : (
                <React.Fragment key={index}>
                  {section.title && <h5>{section.title}</h5>}
                  {section.labeledIcons && (
                    <div className="icon-group">
                      {section.labeledIcons.map((labeledIcon, index) => {
                        // The final two elements in any set are made wider to center them
                        // or something. Can't remember why I did it this way, but it works!
                        const width =
                          section.labeledIcons.length % 3 === 0 ||
                          section.labeledIcons.length - index > 1
                            ? "100px"
                            : "130px";
                        return (
                          <div
                            className="labeled-icon"
                            key={index}
                            style={{ width }}
                          >
                            {labeledIcon.icon && (
                              <i className={labeledIcon.icon} />
                            )}
                            {labeledIcon.image}
                            {typeof labeledIcon.label === "string" ? (
                              <p>{labeledIcon.label}</p>
                            ) : (
                              labeledIcon.label
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {section.content}
                </React.Fragment>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Portfolio;
