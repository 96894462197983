import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Main from "components/Main";
import Timeline from "components/Timeline";
import TravelMap from "components/TravelMap";
import Portfolio from "components/Portfolio";

import "assets/css/fontawesome-free-5.3.1-web/css/all.min.css";

const App = () => (
  <React.Fragment>
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={1000}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Switch location={location}>
                <Route path="/travel" component={TravelMap} />
                <Route path="/portfolio" component={Portfolio} />
                <Route path="/timeline" component={Timeline} />
                <Route path="/" component={Main} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
    <div id="bg"></div>
  </React.Fragment>
);

export default App;
