import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const Header = ({title}) => (
  <div className='page-header' >
  <h1>{title}</h1>
  <Link to='/' >
    <span className='fas fa-times close-button' />
  </Link>
  </div>
)

export default withRouter(Header);