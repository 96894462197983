import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'assets/css/timeline.css';

import ReturnHomeButton from 'components/ReturnHomeButton';
import Header from 'components/Header';

import timelineEvents from 'assets/data/timeline';

// TODO: Make this parse the possible tags out of timelineEvents
const filters = ['professional', 'personal', 'education']

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: ['professional', 'personal', 'education']
    }
  }

  handleFilterClick = (event) => {
    const { name } = event.target;
    if (this.state.filters.includes(name)) {
      this.setState({filters: this.state.filters.filter( el => el !== name)});
    } else {
      this.setState({filters: this.state.filters.concat([ name ])});
    }
  }

  render () {
    return <div>
      <Header title='Experience' />
    {/* <ReturnHomeButton />
    <h1 style={{textAlign: 'center'}}>Experience</h1> */}
    <div className='filter-bar'>
      {filters.map( filter => (
        <a key={filter} name={filter} onClick={this.handleFilterClick} className={this.state.filters.includes(filter) ? 'active' : ''}>
          {this.state.filters.includes(filter) ? <React.Fragment><i className='fas fa-check' />&nbsp;{filter}</React.Fragment> : filter}
        </a>
      ))}
    </div>
    <VerticalTimeline>
      {timelineEvents.filter( event => this.state.filters.includes(event.tag)).map( (event, index) => (
        <VerticalTimelineElement
        className="vertical-timeline-element"
        key={index}
        {...event}
        >
          <h3 className="vertical-timeline-element-title">{event.title}</h3>
          <h4 className="vertical-timeline-element-subtitle">{event.subtitle}</h4>
          {event.text}
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  </div>
  }
}

export default Timeline;