// const travelLog = {
//   "United States": {
//     visited: true,
//     lived: true,
//   },
// }

import React from 'react';

export const travelLog = [
  "Canada",
  "Mexico",
  "Belize",
  "United States",
  "Dominican Republic",
  "Germany",
  "Switzerland",
  "Romania",
  "Italy",
  "Japan",
  "Republic of Korea",
  "Dem. Rep. Korea",
  "Monaco",
  "San Marino",
  "Vatican City",
  "Sweden",
  "Norway",
  "Finland",
  "Croatia",
  "Montenegro",
  "Serbia",
  "Kosovo",
  "Slovenia",
  "Slovakia",
  "Albania",
  "Russian Federation",
  "Thailand",
  "Vietnam",
  "Egypt",
  "Morocco",
  "United Arab Emirates",
  "Israel",
  "Palestine",
  "Jordan",
  "Afghanistan",
  "India",
  "Australia",
  "Denmark",
  "Poland",
  "Ukraine",
  "Bulgaria",
  "Luxembourg",
  "Liechtenstein",
  "South Africa",
  "Swaziland",
  "Lesotho",
  "Spain",
  "France",
  "Portugal",
  "United Kingdom",
  "Ireland",
  "Iceland",
  "Belarus",
  "Lithuania",
  "Czech Republic",
  "Latvia",
  "Estonia",
  "Bosnia and Herzegovina",
  "Austria",
  "Hungary",
  "Belgium",
  "Netherlands",
  "Cambodia",
  "Turkey",
  "Greece",
  "Macedonia",
  "Moldova",
  "Malta",
  "Cyprus",
  "Andorra",
  "Argentina",
  "Bolivia",
  "Chile",
  "Peru",
  "Uruguay",
  "Costa Rica"
]

export const adventureJournal = [
  {
    coordinates: [5.8, 51.8],
    name: 'Nijmegen',
    title: 'Nijmegen Four Days March (Vierdaagse)',
    text: (
      <p>
        <img src='assets/images/nijmegen.jpg' />
        For four days each July, thousands of people march for 100 miles over four days in, around, and through the city of Nijmegen, Netherlands. I did the march with my military unit, starting every morning at 3 AM and walking for 10 hours with a weighted pack. The last day ends with a parade and festivities all night long.
      </p>
    ),
    markerOffset: -35,
  },
  {
    coordinates: [31, -29.9],
    name: 'Durban',
    title: 'Durban Rope Swing',
    text: (
      <p>
        <img src='assets/images/durban-rope-swing.jpg' />
      </p>
    ),
    markerOffset: -35,
  },
  {
    coordinates: [-98.1, 16.3],
    name: 'Oaxaca',
    title: 'PUR Water Program with P&G',
    text: (
      <p>

        Three-week trip to Oaxaca with Procter &amp; Gamble to study the feasibility of large-scale water purifier distribution
      </p>
    ),
    markerOffset: -35,
  },
  {
    coordinates: [-1.64, 42.82],
    name: 'Pamplona',
    title: 'Run With the Bulls',
    text: (
      <p>
        <img src='assets/images/running-bulls.jpg' />
      </p>
    ),
    markerOffset: -35,
  },
  {
    coordinates: [30.2, 51.2],
    name: 'Chernobyl',
    title: 'Chernobyl Disaster Site',
    text: (
      <p>
        <img src='assets/images/chernobyl-geiger.jpg' />
      </p>
    ),
    markerOffset: -35,
  },
  {
    coordinates: [55.2, 25.1],
    name: 'Dubai',
    title: 'Skydive the Palm',
    text: (
      <p>
        <img src='assets/images/skydive-dubai.jpg' />
      </p>
    ),
    markerOffset: -35,
  },
  // {
  //   coordinates: [-80, 15],
  //   name: 'Nazare',
  //   title: 'Base Jumping Course',
  //   text: (
  //     <p>
  //       Learn to BASE jump from one of the most world-wise men I've ever known.
  //     </p>
  //   ),
  //   markerOffset: -35,
  // }
]