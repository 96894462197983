import React from "react";

const portfolio = [
  {
    title: "Expertise",
    sections: [
      {
        title: "Fluent",
        labeledIcons: [
          {
            icon: "fas fa-project-diagram",
            label: (
              <p>
                Systems Design
                <br />
                &amp; Optimization
              </p>
            ),
          },
          { icon: "fab fa-aws", label: "AWS Development" },
          { icon: "fas fa-link", label: "API Design" },
          { icon: "fab fa-python", label: "Python" },
          { icon: "icon-golang", label: "Golang" },
          { icon: "icon-typescript", label: "TypeScript" },
          { icon: "fab fa-react", label: "React Web" },
          { icon: "icon-graphql", label: "GraphQL" },
          { icon: "icon-rustacean", label: "Rust" },
        ],
      },
      {
        title: "Competent",
        labeledIcons: [
          { icon: "fab fa-react", label: "React Native" },
          { icon: "icon-postgresql", label: "PostgreSQL" },
          { icon: "icon-docker", label: "DevOps & CI/CD" },
          { icon: "icon-ruby", label: "Ruby" },
          { icon: "icon-rails", label: "Rails" },
        ],
      },
      {
        title: "Learning",
        labeledIcons: [
          { icon: "fas fa-broadcast-tower", label: "Signal Processing" },
        ],
      },
    ],
  },
  {
    title: "Experience",
    sections: [
      {
        custom: (
          <>
            <h5>Highlights</h5>
            <p>
              Leading a team of subject matter experts to build the world's most
              performant cloud-based quantum computing platform.
            </p>
            <div className="divider" />
            <p>
              Architected &amp; implemented a fast-paced, high-stakes
              integration between a startup and cloud giant
            </p>
            <div className="divider" />
            <p style={{ paddingBottom: 20 }}>
              Drove process improvements and applications across a major tech
              company
            </p>
          </>
        ),
      },
      {
        title: "Experience",
        labeledIcons: [
          { icon: "fab fa-aws", label: "Big Tech" },
          { icon: "icon-bloch-sphere", label: "Hard-Tech Startup" },
          { icon: "fab fa-empire", label: "Government" },
        ],
      },
      {
        title: "Background",
        labeledIcons: [
          { icon: "fas fa-users", label: "Leadership" },
          { icon: "fas fa-unlock", label: "Offensive Security" },
          { icon: "icon-cannon", label: "Artillery" },
        ],
      },
    ],
  },
];

export default portfolio;
