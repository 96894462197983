import React from "react";
import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";

import ProfileImage from "assets/images/kalan-profile.jpg";

import "assets/css/custom.css";
import "assets/css/main.css";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtitleIndex: 0,
      subtitles: [
        "Software Architect",
        "Technical Leader",
        "Full-Stack Engineer",
        "Adventurer",
        "Army Veteran",
      ],
      height: 0,
    };
  }

  componentDidMount() {
    this.timerId = setInterval(
      () =>
        this.setState({
          subtitleIndex:
            (this.state.subtitleIndex + 1) % this.state.subtitles.length,
        }),
      5000
    );
    this.setState({ height: "auto" });
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    return (
      <React.Fragment>
        <header id="header" className="center-screen">
          {/* <div className="logo"> */}
          <img className="logo" src={ProfileImage} />
          {/* </div> */}
          <div className="content">
            <AnimateHeight
              duration={1500}
              height={this.state.height}
              easing="cubic-bezier(0.77, 0, 0.175, 1)"
            >
              <div className="inner">
                <h1>Kalan Snyder</h1>
                <p>{this.state.subtitles[this.state.subtitleIndex]}</p>
                {/* {this.state.subtitles.map((text, index) => (
              <Transition key={index} animation='scale' visible={index === this.state.subtitleIndex} duration={500}><p>{text}</p></Transition>
            ))} */}
              </div>
            </AnimateHeight>
          </div>
          <nav>
            <ul>
              <li>
                <Link to="/timeline">Experience</Link>
              </li>
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li>
                <Link to="/travel">Adventures</Link>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/kalan-snyder/">LinkedIn</a>
              </li>
            </ul>
          </nav>
        </header>
        <div className="text-footer">
          background - sani pass - lesotho - dec 2016
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
